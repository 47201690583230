import AuthGuard from "../guards/authGuard/AuthGuard";
import {Navigate} from "react-router-dom";
import GuestGuard from "../guards/authGuard/GuestGaurd";
import React, {lazy} from "react";
import Loadable from "../layouts/full/shared/loadable/Loadable";

const VoucherDetails = Loadable(lazy(() => import('../views/voucher-details/VoucherDetails')));

const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const ViewWrapper = Loadable(lazy(() => import('./wrapper/ViewWrapper')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Settings = Loadable(lazy(() => import('../views/retailer-portal-pages/settings-page')));
const Help = Loadable(
    lazy(() => import('../views/retailer-portal-pages/help-page')),
);
const ConfirmEmail = Loadable(lazy(() => import('../views/authentication/auth1/ConfirmEmail')));

const VoucherList = Loadable(lazy(() => import('../views/retailer-portal-pages/voucher/voucher-list')));
const MarketingMaterials = Loadable(lazy(() => import('../views/retailer-portal-pages/marketing-materials')));

const DashboardCards = Loadable(lazy(() => import('../views/dashboard/Main')));

export const baseRetailerURL = process.env.REACT_APP_RETAILER_PORTAL_ROUTE as string;

export const retailerRoutes = [
    {
        path: baseRetailerURL,
        element: (
            <AuthGuard>
                <FullLayout />
            </AuthGuard>
        ),
        children: [
            { path: `${baseRetailerURL}`, element: <Navigate to={`${baseRetailerURL}/vouchers`} /> },

            /*{
                path: `${baseRetailerURL}/dashboard`,
                exact: true,
                element: (
                    <ViewWrapper title="Notifications" description="Notifications page">
                        <DashboardCards />
                    </ViewWrapper>
                ),
            },*/
            {
                path: `${baseRetailerURL}/vouchers`,
                exact: true,
                element: (
                    <ViewWrapper title={'Applications'}>
                        <VoucherList />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseRetailerURL}/account/settings`,
                exact: true,
                element: (
                    <ViewWrapper title="Settings">
                        <Settings />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseRetailerURL}/account/help`,
                exact: true,
                element: (
                    <ViewWrapper title="Help Page">
                        <Help />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseRetailerURL}/vouchers/view-details/:id`,
                exact: true,
                element: (
                    <ViewWrapper title="Application Details">
                        <VoucherDetails />
                    </ViewWrapper>
                ),
            },
            {
              path: `${baseRetailerURL}/marketing-materials`,
              exact: true,
              element: (
                <ViewWrapper title="Marketing Materials">
                  <MarketingMaterials />
                </ViewWrapper>
              ),
            },
            {
                path: `${baseRetailerURL}/404`,
                exact: true,
                element: (
                    <ViewWrapper title={'Error'}>
                        <Error />
                    </ViewWrapper>
                ),
            },
            { path: '*', exact: true, element: <Navigate to={`/404`} /> },
        ],
    },
    {
      path: `${baseRetailerURL}/auth/confirm-email`,
      element: (
        <ViewWrapper title="Confirmation Email" description="Confirmation of an Email">
          <ConfirmEmail />
        </ViewWrapper>
      ),
    },
    {
        path: '/',
        element: (
            <GuestGuard>
                <BlankLayout />
            </GuestGuard>
        ),
        children: [
            { path: `${baseRetailerURL}/auth/login`, element: <Login /> },
        ],
    },
];
