import { fetch, ListEntityResponse } from '..';
import { QueryMethod } from '../utils';
import { NotificationDto } from './dto';

export async function findAllNotifications() {
  return fetch<ListEntityResponse<NotificationDto>>(
    `/gogeta-api/v1/notification?page=1&limit=200&sort=voucherStatus&order=asc`,
    QueryMethod.GET,
  );
}

export async function markNotificationAsRead(id: number): Promise<void> {
  return fetch(`/gogeta-api/v1/notification-marked/mark-as-read/${id}`, QueryMethod.POST);
}
