import { fetch } from '..';
import { QueryMethod } from '../utils';
import { AuthForgotPasswordDto, InviteRetailerDto, RefreshTokenDto } from "./dto";

export async function refreshTokenRequest() : Promise<RefreshTokenDto> {
  const response = await fetch('/gogeta-api/v1/auth/refresh-token', QueryMethod.GET);

  return response as RefreshTokenDto;
}

export function forgotPassword(email: string) {
  return fetch('/gogeta-api/v1/auth/forgot-password', QueryMethod.POST, { email });
}


export async function inviteEmployer(data: AuthForgotPasswordDto) {
  return await fetch(
    '/gogeta-api/v1/auth/invite/employer',
    QueryMethod.POST,
    data,
  );
}

export async function inviteRetailer(data: InviteRetailerDto) {
  return await fetch(
    '/gogeta-api/v1/auth/invite/retailer',
    QueryMethod.POST,
    data,
  );
}
