import {
  NotificationDetails,
  NotificationType,
  RetailerPendingNotificationDto,
  TenantPendingNotificationDto,
  VoucherPendingApprovalNotificationDetailsDto,
  VoucherWasCanceledNotificationDto,
  TenantWasUpdatedNotificationDto,
  InvoiceNotificationDetailsDto,
  TenantTilloGiftCardNotificationDetailsDto,
} from '../../requests/notification/dto';

export const isVoucherPendingApprovalNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is VoucherPendingApprovalNotificationDetailsDto =>
  type === NotificationType.VOUCHER_PENDING_APPROVAL;

export const isNplVoucherPendingApprovalNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is VoucherPendingApprovalNotificationDetailsDto =>
  type === NotificationType.NPL_VOUCHER_PENDING_APPROVAL;

export const isInvoiceNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is InvoiceNotificationDetailsDto => type === NotificationType.INVOICE_OVERDUE;

export const isTenantPendingActivationNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is TenantPendingNotificationDto => type === NotificationType.TENANT_PENDING_ACTIVATION;

export const isRetailerPendingActivationNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is RetailerPendingNotificationDto =>
  type === NotificationType.RETAILER_PENDING_ACTIVATION;

export const isVoucherWasCanceledNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is VoucherWasCanceledNotificationDto => type === NotificationType.VOUCHER_CANCELED;

export const isTenantUpdatedNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is TenantWasUpdatedNotificationDto => type === NotificationType.TENANT_UPDATED;

export const isTenantTilloGiftCardNotificationDetails = (
  type: NotificationType,
  details: NotificationDetails,
): details is TenantTilloGiftCardNotificationDetailsDto =>
  type === NotificationType.TENANT_TILLO_GIFT_CARD;

export interface FormattedNotification {
  id: number;
  type: NotificationType;
  label: string;
  createdAt: Date;
  details: NotificationDetails;
  link?: string;
}
