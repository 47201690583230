/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, ReactNode } from 'react';
import uuid from 'react-uuid';
import { EntityResponse, ErrorResponse } from 'src/requests';
import { getTenantSettings, updateTenant } from '../requests/tenant';
import useAuth from 'src/guards/authGuard/UseAuth';
import { TenantSettingsDto } from '../requests/tenant/dto';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getEmployeeVoucherLinkDetails } from '../requests/user-employee-settings';
import { EmployeeVoucherLinkDetailsDto } from '../requests/user-employee-settings/dto';
import { uploadPDFFilesUsingBlob } from '../requests/file';
import { getPortalType } from 'src/guards/jwt/utils';

interface PortalContextProps {
  children: ReactNode;
}

interface PortalSettingsCtx extends Partial<TenantSettingsDto> {
  actions: {
    isSuccess: boolean;
    isLoading: boolean;
    updateTenantSettings: (payload: UpdateTenantSettingsMutation) => void;
  };
}

interface UpdateTenantSettingsMutation {
  showIntroduceDialogMenu?: boolean;
  file?: Blob;
}

const intitalCtxState: PortalSettingsCtx = {
  actions: {
    isSuccess: false,
    isLoading: false,
    updateTenantSettings: () => Promise.resolve(),
  },
};

const PortalContext = createContext<PortalSettingsCtx>(intitalCtxState);

const PortalProvider = ({ children }: PortalContextProps) => {
  const { isAuthenticated, isNotAdmin, isRetailer, isEmployee } = useAuth();
  const isInPortal = getPortalType(window.location.pathname);

  const { data } = useQuery<EntityResponse<TenantSettingsDto>, ErrorResponse>({
    queryKey: ['tenant-settings'],
    queryFn: getTenantSettings,
    enabled:
      isInPortal !== undefined && isAuthenticated && isNotAdmin && !isRetailer && !isEmployee,
  });

  const employeeSettings = useQuery<EntityResponse<EmployeeVoucherLinkDetailsDto>, ErrorResponse>({
    queryKey: ['employee-voucher-link-details'],
    queryFn: getEmployeeVoucherLinkDetails,
    enabled: isAuthenticated && isEmployee,
  });

  const tenantSettingsMutation = useMutation({
    mutationFn: async (payload: UpdateTenantSettingsMutation) => {
      let employerServiceAgreementFileName;

      if (payload.file) {
        const agreementName = `${uuid()}-employer-agreement.pdf`;
        const { key } = await uploadPDFFilesUsingBlob(payload.file, agreementName);
        employerServiceAgreementFileName = key;
      }

      return await updateTenant({
        showIntroduceDialogMenu: Boolean(
          payload.showIntroduceDialogMenu ?? data?.showIntroduceDialogMenu,
        ),
        employerServiceAgreementFileName,
      });
    },
  });

  const updateTenantSettings = (payload: UpdateTenantSettingsMutation) =>
    tenantSettingsMutation.mutate(payload);

  return (
    <PortalContext.Provider
      value={{
        ...(data ? data : employeeSettings.data ? employeeSettings.data : {}),
        actions: {
          isSuccess: tenantSettingsMutation.isSuccess,
          isLoading: tenantSettingsMutation.isLoading,
          updateTenantSettings,
        },
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};

export { PortalContext, PortalProvider };

export const usePortalSettingsCtx = () => React.useContext(PortalContext);
