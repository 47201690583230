import { RoleEnum } from 'src/requests/auth/dto';

export function getPortalType(roleString: string) {
  switch (true) {
    case roleString.includes(`${RoleEnum.ADMIN}-portal`):
      return RoleEnum.ADMIN;
    case roleString.includes(`${RoleEnum.RETAILER}_portal`):
      return RoleEnum.RETAILER;
    case roleString.includes(`${RoleEnum.EMPLOYEE}_portal`):
      return RoleEnum.EMPLOYEE;
    case roleString.includes(`/portal`):
      return RoleEnum.EMPLOYER;
  }
}
