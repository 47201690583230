export type RefreshTokenDto = {
    token: string,
    user: UserDto,
}

export interface JwtTokenDto {
    deityMode?: boolean;
    exp: number;
    iat: number;
}

export interface UserDto extends Partial<JwtTokenDto> {
    id: number;
    email: string | null;
    tenantId?: number;
    provider: string;
    socialId: string | null;
    firstName: string | null;
    lastName: string | null;
    photo?: FileDto | null;
    roles?: RoleEnum[] | null;
    status?: StatusDto;
    hash: string | null;
}

export interface AuthUser {
    id: number;
    email?: string;
    tenantId: number;
    roles: RoleEnum[];
}

export interface FileDto {
    id: string;
    path: string;
}

export interface StatusDto {
    id: number;
    name?: string;
}

export enum RoleEnum {
    ADMIN = 'admin',
    EMPLOYER = 'employer',
    RETAILER = 'retailer',
    EMPLOYEE = 'employee',
}

export interface AuthForgotPasswordDto {
    email: string;
    tenantId: number;
}

export interface InviteRetailerDto {
  email: string;
  retailerId: number;
}

