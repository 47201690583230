import React, { lazy, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import GuestGuard from '../guards/authGuard/GuestGaurd';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

const Retailers = Loadable(lazy(() => import('../views/admin-portal/retailers/Retailers')));
const TenantList = Loadable(lazy(() => import('../views/admin-portal/tenant-list')));
const Tenant = Loadable(lazy(() => import('../views/admin-portal/tenant')));
const Retailer = Loadable(lazy(() => import('../views/admin-portal/retailer')));
const TenantCreate = Loadable(lazy(() => import('../views/admin-portal/tenant-create')));
const TenantEdit = Loadable(lazy(() => import('../views/admin-portal/tenant-edit')));
const EmployerInvite = Loadable(lazy(() => import('../views/admin-portal/employer-invite')));
const EmployerList = Loadable(lazy(() => import('../views/admin-portal/employer-list')));
const InvoiceList = Loadable(lazy(() => import('../views/admin-portal/invoice-list')));
const PayrollSummary = Loadable(lazy(() => import('../views/admin-portal/payroll-summary')));
const PersonList = Loadable(lazy(() => import('../views/admin-portal/person-list')));

const DashboardCards = Loadable(lazy(() => import('../views/dashboard/Main')));

const VoucherList = Loadable(lazy(() => import('../views/admin-portal/voucher-list')));
const VoucherDetails = Loadable(lazy(() => import('../views/voucher-details/VoucherDetails')));
const VoucherEdit = Loadable(lazy(() => import('../views/admin-portal/voucher-edit')));
const CreateRetailer = Loadable(lazy(() => import('../views/admin-portal/create-retailer')));
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const LetsTalkPage = Loadable(lazy(() => import('../views/lets-talk/LetsTalk')));
const ViewWrapper = Loadable(lazy(() => import('./wrapper/ViewWrapper')));


import AuthGuard from 'src/guards/authGuard/AuthGuard';
import { isNotSubdomain } from './portal';

export const baseAdminURL = process.env.REACT_APP_ADMIN_PORTAL_ROUTE as string;

const guestPages = [
  {
    path: `${baseAdminURL}`,
    exact: true,
    element: <LetsTalkPage />,
  },
];

export const adminRoutes = [
  {
    path: baseAdminURL,
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: `${baseAdminURL}`, element: <Navigate to={`${baseAdminURL}/dashboard`} /> },
      { path: `${baseAdminURL}/dashboard`, exact: true,
        element: (
            <ViewWrapper
                title="Notifications"
                description="Notifications page">
              <DashboardCards />
            </ViewWrapper>
        )
      },
      {
        path: `${baseAdminURL}/vouchers`,
        exact: true,
        element: (
          <ViewWrapper title={'Vouchers'}>
            <VoucherList />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/vouchers/:id`,
        exact: true,
        element: (
          <ViewWrapper title={'Voucher'}>
            <VoucherDetails />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/vouchers/:id/update`,
        exact: true,
        element: (
            <ViewWrapper title={'Voucher update'}>
              <VoucherEdit />
            </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/retailers`,
        exact: true,
        element: (
          <ViewWrapper title={'Retailers'}>
            <Retailers />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/payroll-summary`,
        exact: true,
        element: (
          <ViewWrapper title={'Payroll Summary'}>
            <PayrollSummary />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/retailers/create`,
        exact: true,
        element: (
          <ViewWrapper title={'Create Retailer'}>
            <CreateRetailer />
          </ViewWrapper>
        ),
      },

      {
        path: `${baseAdminURL}/tenants`,
        exact: true,
        element: (
          <ViewWrapper title={'Employers'}>
            <TenantList />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/tenants/create`,
        exact: true,
        element: (
          <ViewWrapper title={'Create Employers'}>
            <TenantCreate />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/tenants/:tenantId`,
        exact: true,
        element: (
          <ViewWrapper title={'Employer'}>
            <Tenant />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/retailers/:retailerId`,
        exact: true,
        element: (
            <ViewWrapper title={'Retailer'}>
              <Retailer />
            </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/tenants/:tenantId/edit`,
        exact: true,
        element: (
          <ViewWrapper title={'Edit Employers'}>
            <TenantEdit />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/tenants/:tenantId/employers`,
        exact: true,
        element: (
          <ViewWrapper title={'Employer'}>
            <EmployerList />
          </ViewWrapper>
        ),
      },

      {
        path: `${baseAdminURL}/tenants/:tenantId/employers/invite`,
        exact: true,
        element: (
          <ViewWrapper title={'Employer Invite'}>
            <EmployerInvite />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/invoices`,
        exact: true,
        element: (
          <ViewWrapper title={'Invoices'}>
            <InvoiceList />
          </ViewWrapper>
        ),
      },
      {
        path: `${baseAdminURL}/tenants/:tenantId/persons`,
        exact: true,
        element: (
          <ViewWrapper title={'Persons'}>
            <PersonList />
          </ViewWrapper>
        ),
      },
      { path: `${baseAdminURL}/settings`, exact: true, element: <div>settings</div> },
      {
        path: `${baseAdminURL}/404`,
        exact: true,
        element: (
          <ViewWrapper title={'Error'}>
            <Error />
          </ViewWrapper>
        ),
      },
      { path: '*', exact: true, element: <Navigate to={`/404`} /> },
    ],
  },
  {
    path: '/',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [
      { path: `${baseAdminURL}/auth/login`, element: <Login /> },
      ...(isNotSubdomain() ? guestPages : []),
    ],
  },
];
