import { adminRoutes } from './admin';
import { portalRoutes } from './portal';
import { retailerRoutes } from './retailer';
import {employeeRoutes} from "./employee";

export const routes = [
	...portalRoutes,
	...adminRoutes,
	...retailerRoutes,
	...employeeRoutes,
];
