import { TenantSettingsExpanded } from '../tenant/dto';

export interface NotificationDto {
  id: number;
  tenantId?: number;
  userId?: number;
  notificationType: NotificationType;
  notificationDetails: NotificationDetails;
  createdAt: Date;
}

export enum NotificationType {
  VOUCHER_PENDING_APPROVAL = 'VOUCHER_PENDING_APPROVAL',
  VOUCHER_CANCELED = 'VOUCHER_CANCELED',
  INVOICE_OVERDUE = 'INVOICE_OVERDUE',
  TENANT_PENDING_ACTIVATION = 'TENANT_PENDING_ACTIVATION',
  RETAILER_PENDING_ACTIVATION = 'RETAILER_PENDING_ACTIVATION',
  TENANT_UPDATED = 'TENANT_UPDATED',
  NPL_VOUCHER_PENDING_APPROVAL = 'NPL_VOUCHER_PENDING_APPROVAL',
  TENANT_TILLO_GIFT_CARD = 'TENANT_TILLO_GIFT_CARD',
}

interface NotificationDetailsDto {
  id: number;
}

export interface VoucherPendingApprovalNotificationDetailsDto extends NotificationDetailsDto {
  firstName: string;
  lastName: string;
  voucherNumber: string;
}

export interface InvoiceNotificationDetailsDto extends NotificationDetailsDto {
  invoiceNumber: string;
  tenant?: {
    tenantId: number;
    tenantName: string;
  };
}

export interface TenantPendingNotificationDto extends NotificationDetailsDto {
  accountNumber: string;
  tenantName: string;
}
export interface RetailerPendingNotificationDto extends NotificationDetailsDto {
  retailerNumber: string;
  fullCompanyName: string;
}

export interface TenantWasUpdatedNotificationDto extends NotificationDetailsDto {
  addedFields: Partial<TenantSettingsExpanded>;
  updatedFields: Partial<TenantSettingsExpanded>;
  deletedFields: Partial<TenantSettingsExpanded>;
  tenant: {
    tenantId: number;
    tenantName: string;
  };
}

export interface VoucherWasCanceledNotificationDto extends NotificationDetailsDto {
  voucherNumber: string;
  cancellationReason?: string;
  canceledBy: {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  tenant: {
    tenantId: number;
    tenantName: string;
  };
}

export interface TenantTilloGiftCardNotificationDetailsDto extends NotificationDetailsDto {
  tenantName: string;
  retailerCompanyName: string;
}

export type NotificationDetails =
  | TenantWasUpdatedNotificationDto
  | VoucherWasCanceledNotificationDto
  | VoucherPendingApprovalNotificationDetailsDto
  | InvoiceNotificationDetailsDto
  | TenantPendingNotificationDto
  | RetailerPendingNotificationDto
  | TenantTilloGiftCardNotificationDetailsDto;
