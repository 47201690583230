import { NotificationDto } from '../../requests/notification/dto';
import { baseAdminURL, basePortalURL } from '../../routes';
import { isPortalApplication } from '../../utils/common';
import {
  FormattedNotification,
  isInvoiceNotificationDetails,
  isNplVoucherPendingApprovalNotificationDetails,
  isRetailerPendingActivationNotificationDetails,
  isTenantPendingActivationNotificationDetails,
  isTenantTilloGiftCardNotificationDetails,
  isTenantUpdatedNotificationDetails,
  isVoucherPendingApprovalNotificationDetails,
  isVoucherWasCanceledNotificationDetails,
} from './types';

export const formatNotificationDetails = (notification: NotificationDto) => {
  const { notificationDetails: details, notificationType: type } = notification;

  const formattedNotification: FormattedNotification = {
    id: notification.id,
    type: type,
    label: `Unknown notification type: ${type}`,
    createdAt: notification.createdAt,
    details,
  };

  const baseLink = isPortalApplication() ? basePortalURL : baseAdminURL;

  if (isVoucherPendingApprovalNotificationDetails(type, details)) {
    formattedNotification.label = `Approve Application #${details.voucherNumber} for ${details.firstName} ${details.lastName}`;
    if (isPortalApplication()) {
      formattedNotification.link = `${baseLink}/vouchers/view-details/${details.id}`;
    } else {
      formattedNotification.link = `${baseLink}/vouchers/${details.id}`;
    }
  } else if (isNplVoucherPendingApprovalNotificationDetails(type, details)) {
    formattedNotification.label = `Approve NPL Application #${details.voucherNumber} for ${details.firstName} ${details.lastName}`;
    if (isPortalApplication()) {
      formattedNotification.link = `${baseLink}/vouchers/view-details/${details.id}`;
    }
  } else if (isTenantPendingActivationNotificationDetails(type, details)) {
    formattedNotification.label = `Employer #${details.accountNumber}`;
    formattedNotification.link = `${baseLink}/tenants/${details.id}`;
  } else if (isRetailerPendingActivationNotificationDetails(type, details)) {
    formattedNotification.label = `Retailer #${details.retailerNumber}`;
    formattedNotification.link = `${baseLink}/retailers/${details.id}`;
  } else if (isInvoiceNotificationDetails(type, details)) {
    formattedNotification.label = `Invoice (${details.invoiceNumber}) Sent ${
      details.tenant ? `to ${details.tenant.tenantName}` : ''
    } for Payment. `;
    formattedNotification.link = `${baseLink}/invoices`;
  } else if (isVoucherWasCanceledNotificationDetails(type, details)) {
    formattedNotification.label = `Voucher (${details.voucherNumber}) has been canceled.`;
    formattedNotification.link = `${baseLink}/vouchers/${details.id}`;
  } else if (isTenantUpdatedNotificationDetails(type, details)) {
    formattedNotification.label = `Employer (${details.tenant.tenantName}) has updated Cycle to Work scheme.`;
  } else if (isTenantTilloGiftCardNotificationDetails(type, details)) {
    formattedNotification.label = `Employer ${details.tenantName} got their first application. Buy the Tillo gift card and send to the shop ${details.retailerCompanyName}.`;
  }

  return formattedNotification;
};
