import { baseAdminURL } from '../../routes';

export const isPortalApplication = () => {
  return !window.location.pathname.includes(baseAdminURL);
};

export const handleKeyPressPhoneNumber = (event: any) => {
  const pattern = /^[+0-9]*$/;
  const inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
};
