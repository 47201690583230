import { fetch } from '../index';
import { QueryMethod } from '../utils';
import { EmployeeVoucherLinkDetailsDto } from './dto';
import { AddressInformationRequest } from '../../views/account-settings/requestObjects/intex';
import { RetailerDtoWithShopsAndFeedAuthentication } from '../retailer/dto';

export async function getEmployeeVoucherLinkDetails() {
    const response = await fetch<EmployeeVoucherLinkDetailsDto>('/gogeta-api/v1/user-employee-settings/voucher-link-details', QueryMethod.GET);

    return response;
}

export async function getEmployeeAddressInformationSettings() {
    const response = await fetch<AddressInformationRequest>('/gogeta-api/v1/user-employee-settings/information', QueryMethod.GET);

    return response;
}

export async function getRetailerInformationSettings() {
  const response = await fetch<RetailerDtoWithShopsAndFeedAuthentication>('/gogeta-api/v1/retailer/retailer-by-user', QueryMethod.GET);

  return response;
}
