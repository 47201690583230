import axios from 'axios';
import { toast } from 'sonner';

import useAuth from '../guards/authGuard/UseAuth';
import { IS_DEV } from './config';
import { getPortalType } from 'src/guards/jwt/utils';

const axiosServices = axios.create();

const excludedEndpoints = ['accept-voucher', 'confirm-paid-invoice'];

// interceptor for http
const AxiosInterceptor = ({ children }) => {
  const { logout, refreshToken, user } = useAuth();

  // set jwt token to header
  axiosServices.interceptors.request.use(
    (config) => {
      const portalType = getPortalType(window.location.pathname);
      const token = localStorage.getItem(`${portalType?.toLowerCase()}-token`);

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    async (error) => {
      return Promise.reject(error);
    },
  );

  // refresh token if 401 status
  axiosServices.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            await refreshToken();

            return axiosServices(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else if (err.response.status === 403 && err.response.data) {
          if (!IS_DEV) {
            logout(user.roles[0]);
          }

          return Promise.reject(err.response.data);
        } else if (
          err.response.status === 409 &&
          excludedEndpoints.some((e) => err.request.responseURL.includes(e))
        ) {
          err.config._errorToasted = true;
        } else {
          // show toast with error message
          if (!err.config._errorToasted) {
            err.config._errorToasted = true;
            toast.error(
              err.response.data.error ||
                err.response.data.message ||
                err.response.data.status ||
                'Unknown error',
            );
          }
        }
      }

      return Promise.reject(err);
    },
  );

  return children;
};

export default axiosServices;
export { AxiosInterceptor };
