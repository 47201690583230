import { styled } from 'styled-components';

interface IScCookieWrapper {
  isOpen: boolean;
}

export const ScCookieWrapper = styled.div<IScCookieWrapper>`
  width: 100vw;
  z-index: 100000;
  height: fit-content;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  position: fixed;
  width: 100%;
  left: 0;
  padding: 35px 25px;
  box-sizing: border-box;
  background-color: #f85e1d;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  transition: all 0.5s linear;
  > svg {
    min-width: 48px;
    min-height: 48px;
  }
  p {
    font: 400 16px / 24px GeomanistRegular;
    color: #f7f0f0;
    width: 100%;
    a {
      font: 400 16px / 24px GeomanistRegular;
      color: #f7f0f0;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 1920px) {
    gap: 50px;
  }
`;

export const ScCookieButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: fit-content;
  align-items: center;
  button {
    path {
      stroke: #f7f0f0;
    }
    font: 500 16px / 20px GeomanistMedium;
    width: 200px;
    background: #28272f;
    cursor: pointer;
    border: 2px solid #28272f;
    color: #f7f0f0;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 7px 14px;
    transition: 0.2s linear;
    &:hover {
      background: transparent;
      path {
        stroke: #28272f;
      }
      color: #28272f;
    }
  }
  button:first-child {
    background: transparent;
    color: #28272f;
    path {
      stroke: #28272f;
    }
    &:hover {
      background: #28272f;
      path {
        stroke: #f7f0f0;
      }
      color: #f7f0f0;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    button {
      padding: 15px 30px;
    }
  }
`;
