import { Location } from "react-router-dom";
import {baseAdminURL, baseEmployeeURL, basePortalURL, baseRetailerURL} from "../../routes";
import { AuthContextType } from "../jwt/JwtContext";

interface PermissionsAndBaseRoutePath {
  admin: boolean;
  shouldRedirect: boolean;
  path: string;
}

const locationHelpers = (location: Location) => {
  return {
    getBasePath: () => {
      return location.pathname.includes(baseAdminURL) ? baseAdminURL
          : location.pathname.includes(baseRetailerURL) ? baseRetailerURL
          : location.pathname.includes(baseEmployeeURL) ? baseEmployeeURL
          : basePortalURL;
    },
    getRedirectTo: () => {
      return location.hash && new URLSearchParams(location.hash.substring(1)).get('redirectTo');
    },
  };
};

export const getPermissionsAndBaseRoutePath = (ctx: AuthContextType, location: Location) => {
  const { getBasePath, getRedirectTo } = locationHelpers(location);

  const result: PermissionsAndBaseRoutePath = {
    admin: false,
    shouldRedirect: !location.pathname.includes(basePortalURL) && !location.pathname.includes(baseRetailerURL),
    path: getBasePath(),
  };

  // when user is not authenticated
  if (!ctx.isAuthenticated) {
    // set path to login page when user is not authenticated
    result.path = result.path.concat('/auth/login');

    return result;
  }

  // when user is authenticated
  if (ctx.isAdmin) {
    result.admin = true;
    result.shouldRedirect = !location.pathname.includes(baseAdminURL);
    result.path = baseAdminURL;
  } else if (ctx.isSuperAdmin) {
    result.admin = true;
    result.shouldRedirect = !location.pathname.includes(baseAdminURL);
    result.path = getBasePath();
  } else if (ctx.isRetailer) {
    result.shouldRedirect = !location.pathname.includes(baseRetailerURL),
    result.admin = false;
    result.path = baseRetailerURL;
  } else if (ctx.isEmployee) {

    result.shouldRedirect = !location.pathname.includes(baseEmployeeURL),
    result.admin = false;
    result.path = baseEmployeeURL;
  } else {

    result.admin = false;
    result.path = basePortalURL;
  }

  // get redirect-path from hash and set to path
  const redirectTo = getRedirectTo();
  if (redirectTo) {
    result.path = result.path.concat(`/${redirectTo}`);
  }

  return result;
};
