import { fetch } from "../index";
import { QueryMethod } from "../utils";
import { ExportTableDataRequest, ExportTableDataResponseDto } from "./dto";


export async function exportTableData(payload: ExportTableDataRequest) {
  return await fetch<ExportTableDataResponseDto>(
    '/gogeta-api/v1/export-csv',
    QueryMethod.POST,
    payload,
  );
}

export async function getExportTableDataStatus(payload: { id: string }) {
  return await fetch<ExportTableDataResponseDto>(
    `/gogeta-api/v1/export-csv/status/${payload.id}`,
    QueryMethod.GET,
  );
}

