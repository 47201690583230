/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useReducer, useMemo, useState, ReactElement } from 'react';
import { decodeToken } from 'react-jwt';

import axios from 'src/utils/axios';
import { RoleEnum, UserDto } from '../../requests/auth/dto';
import { basePortalURL } from '../../routes';
import { isValidToken, setRefreshToken, setSession } from './Jwt';
import { refreshTokenRequest } from '../../requests/auth';
import { getPortalType } from './utils';

const API_URL = process.env.REACT_APP_API_URL || 'https://product.velomatch.io';

export interface InitialStateType {
  isAuthenticated: boolean;
  isInitialized?: boolean;
  user?: UserDto | null;
}

export interface AuthContextType extends InitialStateType {
  [k: string]: unknown;
  platform: 'JWT';
  method: 'jwt';
  signup: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  signin: (email: string, password: string, pageToLogin: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  logout: (portalType: RoleEnum) => Promise<void>;
  resetPassword: (hash: string, password: string, resetPassword: string) => Promise<void>;
  setUpDeityMode: (tenantId: string) => Promise<void>;
  isEmployer: boolean;
  isRetailer: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isNotAdmin: boolean;
  isEmployee: boolean;
}

const initialState: InitialStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: any = {
  INITIALIZE: (state: InitialStateType, action: any) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: InitialStateType, action: any) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: InitialStateType) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: InitialStateType, action: any) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: InitialStateType, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextType>({
  ...initialState,
  method: 'jwt',
  platform: 'JWT',
  signup: () => Promise.resolve(),
  signin: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setUpDeityMode: (tenantId: string) => Promise.resolve(),
  isEmployer: false,
  isNotAdmin: false,
  isAdmin: false,
  isRetailer: false,
  isSuperAdmin: false,
  isEmployee: false,
});

function AuthProvider({ children }: { children: ReactElement }) {
  const [initialization, setInitialization] = useState(true);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const portalType = getPortalType(window.location.pathname);
    const initialize = async () => {
      try {
        const token = window.localStorage.getItem(`${portalType?.toLowerCase()}-token`);
        const refreshJwtToken = window.localStorage.getItem(
          `${portalType?.toLowerCase()}-refresh-token`,
        );

        if (token && refreshJwtToken && isValidToken(refreshJwtToken)) {
          if (token && !isValidToken(token)) {
            setRefreshToken(refreshJwtToken, portalType);
            await refreshToken();
          } else {
            setSession(token, portalType);
            setRefreshToken(refreshJwtToken, portalType);

            const user = decodeToken<Partial<UserDto>>(token);
            if (!user) {
              throw new Error('Invalid token.');
            }

            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: { ...(state.user ?? {}), ...user },
              },
            });
          }
        } else {
          await logout(portalType);
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      } finally {
        setInitialization(false);
      }
    };

    initialize();
  }, []);

  const signin = async (email: string, password: string, pageToLogin: string) => {
    const response = await axios.post(API_URL + '/gogeta-api/v1/auth/email/login', {
      email,
      password,
      pageToLogin,
    });

    const { token, user, refreshToken } = response.data;
    setSession(token, pageToLogin);
    setRefreshToken(refreshToken, pageToLogin);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const refreshToken = async () => {
    const response = await refreshTokenRequest();

    const { token, user } = response;
    if (user.roles && user.roles[0]) {
      setSession(token, user.roles[0]);
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const signup = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post(API_URL + '/gogeta-api/v1/auth/admin/email/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { token, user } = response.data;

    window.localStorage.setItem('token', token);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const resetPassword = async (hash: string, password: string, confirmPassword: string) => {
    const response = await axios.post(API_URL + '/gogeta-api/v1/auth/reset/password', {
      hash,
      password,
      confirmPassword,
    });
    const { token, user, refreshToken } = response.data;

    setSession(token, user.roles[0]);
    setRefreshToken(refreshToken, user.roles[0]);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = async (portalType?: RoleEnum) => {
    if (portalType) {
      setSession(null, portalType);
      setRefreshToken(null, portalType);
    }
    dispatch({ type: 'LOGOUT' });
  };

  const setUpDeityMode = async (tenantId: string) => {
    const response = await axios.post(API_URL + '/gogeta-api/v1/auth/deity-mode', {
      tenantId,
    });

    const { token, user, refreshToken } = response.data;

    setSession(token, 'employer');
    setRefreshToken(refreshToken, 'employer');

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });

    window.location.replace(`${window.location.origin}${basePortalURL}/dashboard`);
  };

  const { isAdmin, isSuperAdmin, isNotAdmin, isEmployer, isRetailer, isEmployee } = useMemo(() => {
    const permissions = {
      isEmployer: false,
      isAdmin: false,
      isSuperAdmin: false,
      isNotAdmin: false,
      isRetailer: false,
      isEmployee: false,
    };

    if (!state.user) {
      return permissions;
    }

    if (state.user.roles.length === 1) {
      permissions.isAdmin = state.user.roles.includes(RoleEnum.ADMIN);
    }

    if (state.user.roles.length !== 1) {
      permissions.isSuperAdmin = state.user.roles.includes(RoleEnum.ADMIN);
    }

    permissions.isNotAdmin = !state.user.roles.includes(RoleEnum.ADMIN);
    permissions.isEmployer = state.user.roles.includes(RoleEnum.EMPLOYER);
    permissions.isRetailer = state.user.roles.includes(RoleEnum.RETAILER);
    permissions.isEmployee = state.user.roles.includes(RoleEnum.EMPLOYEE);

    return permissions;
  }, [state.user]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signin,
        logout,
        signup,
        resetPassword,
        refreshToken,
        isEmployer,
        isEmployee,
        isRetailer,
        isAdmin,
        isSuperAdmin,
        isNotAdmin,
        setUpDeityMode,
      }}
    >
      {initialization ? null : children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
