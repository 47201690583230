import { PayrollPaymentFrequency } from '../../components/pdf-form/enum';

export enum TenantCountry {
  UK = 'UK',
  IRELAND = 'IRELAND',
}

export enum TenantVersion {
  CLASSIC = 'CLASSIC',
  PREMIUM = 'PREMIUM',
  PREMIUM_PLUS = 'PREMIUM_PLUS',
  BIKE_NOW = 'BIKE_NOW',
  SELF_SERVE = 'SELF_SERVE',
}

export enum TenantStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}


export interface CertificateApplicationDatePeriod {
  startDate: Date;
  endDate: Date;
  active: boolean;
}

export interface PartialTenantSettingsDto {
  tenantUrl: string;
  paymentFrequency: PayrollPaymentFrequency;
  salarySacrificeTerm: number;
  companyName: string;
  companyAccountNumber: string;
  voucherLimit?: number;
  certificateDatePeriodStartDate?: Date;
  certificateDatePeriodEndDate?: Date;
  certificateDatePeriodActive: boolean;
}

export interface TenantInvitationInfo {
  tenantUrl: string;
  companyKnownAs: string;
  paymentFrequency: PayrollPaymentFrequency;
  tenantVersion: TenantVersion;
  salarySacrificeTerm: number;
  companyName: string;
  isDemoTenant: boolean;
  companyAccountNumber: string;
  voucherLimit: number;
  voucherLimitSelfServe: number;
  companyDescription: string;
  platformFeePercent: number;
  employerFeePercent: number;
  employeeFeePercent: number;
  isVoucherLimitUnlimited: boolean;
  isVoucherLimitUnlimitedSelfServe: boolean;
  certificateDatePeriodStartDate: string;
  certificateDatePeriodEndDate: string;
  certificateDatePeriodActive: boolean;
  partnershipSettings: PartnershipSettings;
}

export interface ITenantSettingsSalSacForm {
  employerDate: IEmployerData;
  tenantData: ITenantData;
}

export interface IEmployerData {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ITenantData {
  addressLine1: string;
  addressLine2: string;
  currency: string;
  salarySacrificeTerm: number;
  tenantCountry: TenantCountry;
  county: string;
  town: string;
  postcode: string;
  companyDescription: string;
  paymentFrequency: string;
  payrollNumberRequired: boolean;
  salarySacrificeAgreementCustomWording: string;
  tenantName: string;
  tenantVersion: TenantVersion;

}

export enum NotificationFrequencyEnum {
  UPON_REQUEST = 'UPON_REQUEST',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum OwnershipOptionsEnum {
  MANAGED_BY_GOGETA = 'MANAGED_BY_GOGETA',
}

export interface TenantSettings {
  notification: NotificationFrequencyEnum;
  ownershipOptions: OwnershipOptionsEnum;
  salarySacrificeTerm: number;
  paymentFrequency: PayrollPaymentFrequency;
  payrollNumberRequired: boolean;
  voucherLimit?: number;
  currentYearSchemeLimit: number;
  certificateApplicationDatePeriod: CertificateApplicationDatePeriod;
  companyLogoUrl?: string;
  companyDescription?: string;
  currency: string;
  county: string;
  addressLine1: string;
  addressLine2?: string;
  town: string;
  postcode: string;
  isVoucherLimitUnlimited: boolean;
  payrollContactEmailAddress: string;
  payrollContactFullName: string;
  accountContactEmailAddress: string;
  accountContactFullName: string;
  creditFacilityTermsApply: boolean;
  creditMaxSum: number;
  creditCurrentSum: number;
  isRequiredPurchaseOrderNumber: boolean;
  platformFeePercent?: number;
  employerFeePercent?: number;
  employeeFeePercent?: number;
  employerSavingsPercent?: number;
  voucherLimitSelfServe: number;
  isVoucherLimitUnlimitedSelfServe: boolean;
}
export interface TenantSettingsExpanded extends TenantSettings {
  defaultPurchaseOrderNumber: string;
  voucherLimitSelfServe: number;
  isVoucherLimitUnlimitedSelfServe: boolean;
}

export interface TenantSettingsExtended extends TenantSettings {
  unpaidInvoices?: any[];
  tenantVersion?: TenantVersion;
  voucherLimitSelfServe: number;
  isVoucherLimitUnlimitedSelfServe: boolean;
}

export interface TenantSettingsWithCompanyName extends TenantSettings {
  tenantName: string;
  companyKnownAs: string;
}

export interface PartnershipSettings {
  showXOBikes: boolean;
}

export interface GlobalDetailsSettingsRequest {
  payrollContactEmailAddress: string;
  payrollContactFullName: string;
  accountContactEmailAddress: string;
  accountContactFullName: string;
  county: string;
  addressLine1: string;
  addressLine2?: string;
  town: string;
  postcode: string;
}

export interface GlobalTenantDetailsRequest {
  tenantName: string;
  companyKnownAs: string;
}

export interface GlobalDetailsRequest {
  tenantSetting: GlobalDetailsSettingsRequest;
  tenantDetails: GlobalTenantDetailsRequest;
}

// when employer register tenant by his own the value should be EXPLICITLY otherwise IMPLICITLY
export enum CreationMethod {
  EXPLICITLY = 'EXPLICITLY',
  IMPLICITLY = 'IMPLICITLY',
}

export interface TenantDto {
  id: number;
  tenantUrl: string;
  accountNumber: string;
  tenantVersion: TenantVersion;
  tenantCountry: TenantCountry;
  creationMethod: CreationMethod;
  tenantName: string;
  createdAt: Date;
  status: TenantStatus;
}

export interface TenantSettingsDto extends TenantDto {
  companyKnownAs: string;
  showIntroduceDialogMenu: boolean;
  defaultPurchaseOrderNumber: string;
  settings: TenantSettings;
  employerServiceAgreementFileName: string | null;
  platformFeePercent?: number;
  employerFeePercent?: number;
  employeeFeePercent?: number;
}

export interface TenantSettingsExtendedDto extends TenantSettingsDto {
  settings: TenantSettingsExtended;
}

export interface FullDetailsDto extends TenantDto {
  companyKnownAs: string;
  showIntroduceDialogMenu: boolean;
  defaultPurchaseOrderNumber?: string;
  howYouHeardAboutUs: string;
  companyRegistrationNumber?: string;
  numberOfPAYEmployees: number;
  status: TenantStatus;
  settings: TenantSettings;
  platformFeePercent: number;
  employerFeePercent: number;
  employeeFeePercent: number;
  employerServiceAgreementFileName: string | null;
  howDidYouHearAboutUsSpecifyAnswer: string | null;
  partnershipSettings: PartnershipSettings;
}

export interface InviteEmployerDto {
  adminUserEmail: string;
  tenantId: number;
  adminUserFirstName: string;
  adminUserLastName: string;
  adminJobTitle: string;
  adminPhoneNumber: string;
}

export interface AdminUpdateTenantRequest {
  tenantVersion: TenantVersion;
}

export interface UpdateTenantRequest {
  showIntroduceDialogMenu: boolean;
  employerServiceAgreementFileName?: string;
}

export interface CreateTenantRequest {
  tenantName: string;
  companyKnownAs: string;
  county?: string;
  addressLine1: string;
  addressLine2?: string;
  town?: string;
  postcode?: string;
  companyIsCharity: boolean | string;
  VATNumber: string;
  companyRegistrationNumber?: string;
  numberOfPAYEEmployees: number;
  howYouHeardAboutUs: string;
  adminUserFirstName: string;
  adminUserLastName: string;
  adminJobTitle: string;
  adminPhoneNumber: string;
  adminUserEmail: string;
  tenantVersion: TenantVersion;
  adminInvoiceEmail?: string;
  employerServiceAgreementFileName?: string;
  howDidYouHearAboutUsSpecifyAnswer?: string;
  invoiceContactFullName: string;
}

export interface CreateTenantRequestWithSignedAgreement extends CreateTenantRequest {
  signedAgreement: any;
}

export interface UpdateTenantAgreementAsAdminRequest {
  fileData: string;
  fileName: string;
}
