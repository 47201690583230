/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { getPermissionsAndBaseRoutePath } from './utils';
import useAuth from './UseAuth';
import { portalRoutes } from 'src/routes';

const basePortalURL = process.env.REACT_APP_PORTAL_ROUTE;

export const excludeArrays = [
  `${basePortalURL}/retailer_portal/welcome`,
  `${basePortalURL}/retailer_portal/verify-retailer-and-voucher`,
  `${basePortalURL}/retailer_portal/redeem-voucher`,
  `${basePortalURL}/retailer_portal/register`,
  `${basePortalURL}/retailer_portal/confirm-pickup`,
  `${basePortalURL}/auth/set-password`,
  `${basePortalURL}/auth/confirm-email`,
  `${basePortalURL}/employer_portal/register`,
  `${basePortalURL}/sal-sac-form`,
  '/',
  '/feature-benefits',
  '/plans',
  '/how-it-works',
  '/contact',
  '/contact-us',
  '/retailer',
  '/book-demo',
  '/cookie-policy',
  '/employer-service-agreement',
  '/privacy-policy',
  '/terms-conditions',
  '/invite-your-employer',
  '/why-cycling-matters',
  '/faq',
  '/retailer-faq',
  '/about-us',
  '/service-agreement',
  '/retailer-onboarding',
  '/delivering-for-business',
  '/unbeatable-employee-savings',
  '/productive-employees',
  '/head-of-retail-partnerships',
  '/customer-success-executive',
  '/blog',
  '/blog/:id',
  '/savings-calculator',
  '/easy-admin',
  '/flexi-voucher',
  '/404',
  '/retailer-finder',
  '/b-corp',
  '/insurance',
  '/opt-in',
  '/apply-now',
  '/update-your-website',
];

const GuestGuard = ({ children }: { children: JSX.Element }) => {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { path } = getPermissionsAndBaseRoutePath(authCtx, location);

    if (
      authCtx.isAuthenticated &&
      !(excludeArrays.includes(location.pathname) || location.pathname.includes('npl'))
    ) {
      navigate(path, { replace: true });
    }
  }, [authCtx.isAuthenticated, location.pathname]);

  return children;
};

export default GuestGuard;
