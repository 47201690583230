/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from './UseAuth';
import { getPermissionsAndBaseRoutePath } from './utils';

const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const ctx = useAuth();
  const location = useLocation();

  const { shouldRedirect, path } = getPermissionsAndBaseRoutePath(ctx, location);

  if (!ctx.isAuthenticated || shouldRedirect) {
    return <Navigate to={path} replace />;
  }

  return children;
};

export default AuthGuard;
