import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'src/guards/jwt/JwtContext';
import { AxiosInterceptor } from 'src/utils/axios';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import { store } from './store/Store';
import Spinner from './views/spinner/Spinner';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AuthProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <AxiosInterceptor>
              <App />
            </AxiosInterceptor>
          </BrowserRouter>
        </AuthProvider>
      </Suspense>
    </Provider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
